<mat-toolbar color="primary" *ngIf="this.showButton" >
    <mat-toolbar-row>
     
      <span class="example-spacer"></span>
      <button mat-raised-button color="accent" (click)="addToHomeScreen()">Add to home screen</button>
    </mat-toolbar-row>
  </mat-toolbar>

<router-outlet></router-outlet>

