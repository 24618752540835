import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { VexModule } from '../../@vex/vex.module';
import { CustomLayoutModule } from './../custom-layout/custom-layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    MaterialModule,
    CustomLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
