export const environment = {
  production: false,
  // API_ENDPOINT: 'https://aqua.reshop.live/api/v1/',
  // SOCKET_ENDPOINT: 'https://aqua.reshop.live/init',

  // RTMP_ENDPOINT: 'rtmp://178.128.95.138:1935/live/',

  API_ENDPOINT: 'http://localhost:3000/api/v1/',
  SOCKET_ENDPOINT: 'http://localhost:3000',

  RTMP_ENDPOINT: 'rtmp://localhost:1935/live/',

  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDAHw8IlvfOifMQNSPZQRJZoz-yEuwpKKI',
    authDomain: 'project-bike.firebaseapp.com',
    databaseURL: 'https://project-bike.firebaseio.com',
    projectId: 'project-bike',
    storageBucket: 'project-bike.appspot.com',
    messagingSenderId: '962024703435',
    appId: '1:962024703435:web:97561800c8a39dca2b624e',
  },

  ICE_SERVERS: [
    {
      urls: 'stun:stun.l.google.com:19302',
    },
    {
      urls: 'turn:167.71.225.56:3478',
      credential: '0y54xi731hssn6kwadnk6psebqruw4',
      username: 'aunto',
    },
  ],
};
